import Spinner from 'react-bootstrap/Spinner';

function Spin( props ) {
  const { color } = props;
  
  return (
    <Spinner animation="border" role="status" style={{color: color}}>
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}

export default Spin;