import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useAddNewAlbumMutation } from '../../features/albumsSlice';
import { useAddNewSongMutation } from '../../features/songsSlice';
import { useAddNewAboutMutation } from '../../features/aboutsSlice';

export default function NewContentButton({ contentType }) {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [ desc, setDesc ] = useState('');                     //albums, songs, abouts
    const [ artwork, setArtwork ] = useState('');               //albums, songs, abouts
    const [ title, setTitle ] = useState('');                   //albums, songs
    const [ link, setLink ] = useState('');                     //songs 
    const [ lyrics, setLyrics ] = useState('');                 //songs 
    const [ songs, setSongs ] = useState('');                   //albums


    const onDescChanged = (e) => setDesc(e.target.value);       //albums, songs, abouts
    const onArtworkChanged = (e) => setArtwork(e.target.value); //albums, songs, abouts
    const onTitleChanged = (e) => setTitle(e.target.value);     //albums, songs
    const onLinkChanged = (e) => setLink(e.target.value);       //songs 
    const onLyricsChanged = (e) => setLyrics(e.target.value);   //songs 
    const onSongsChanged = (e) => setSongs(e.target.value);     //albums

    const [ addNewAlbum, { isLoadingAlbum } ] = useAddNewAlbumMutation();
    const [ addNewSong, { isLoadingSong } ] = useAddNewSongMutation();
    const [ addNewAbout, { isLoadingAbout } ] = useAddNewAboutMutation();

    const canSave = !isLoadingAlbum || !isLoadingSong || !isLoadingAbout;

    let content = renderModal(contentType);

    const handleCreate = async (contentType) => {
        setShow(false);

        if (canSave) {
            switch(contentType) {
                case "Album":
                    try {
                        const albumTitle = title;
                        const albumArtwork = artwork;
                        const albumLink = link;
                        const albumDesc = desc;
                        const trimmedSongs = songs.split(" ").join("");
                        const albumSongs = trimmedSongs.split(',');
                                          
                        await addNewAlbum({ albumTitle, albumArtwork, albumLink, albumDesc, albumSongs }).unwrap();
                        setTitle('');
                        setArtwork('');
                        setLink('');
                        setDesc('');
                        setSongs('');
                    }
                    catch (error) {
                        console.log('Failed to save album', error);
                    }
                  break;
                case "Song":
                    try {
                        const songTitle = title;
                        const songArtwork = artwork;
                        const songLink = link;
                        const songLyrics = lyrics;
                        const songDesc = desc;

                        await addNewSong({ songTitle, songArtwork, songLink, songLyrics, songDesc }).unwrap();
                        setTitle('');
                        setArtwork('');
                        setLink('');
                        setLyrics('');
                        setDesc('');
                    }
                    catch (error) {
                        console.log('Failed to save song', error);
                    }
                  break;
                case "About":
                    try {
                        const aboutArtwork = artwork;
                        const aboutDesc = desc;

                        await addNewAbout({ aboutArtwork, aboutDesc }).unwrap();
                        setArtwork('');
                        setDesc('');
                    }
                    catch (error) {
                        console.log('Failed to save about', error);
                    }
                  break;
                default:
            }

            window.location.reload(false);
        }
    }

    function renderModal(contentType) {
        return (
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                <Modal.Title>New {contentType}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>

                        { contentType === "Album" || contentType === "Song" ? 
                            <Form.Group className="mb-3">
                                <Form.Label className="manage-content">Title</Form.Label>
                                <Form.Control type="text"  onChange={onTitleChanged} />
                            </Form.Group>
                            : 
                            <></>
                        }

                        <Form.Group className="mb-3">
                            <Form.Label className="manage-content">Artwork Link</Form.Label>
                            <Form.Control type="text"  onChange={onArtworkChanged} />
                        </Form.Group>

                        { contentType === "Album" || contentType === "Song" ? 
                            <Form.Group className="mb-3">
                                <Form.Label className="manage-content">Landar Link</Form.Label>
                                <Form.Control type="text"  onChange={onLinkChanged} />
                            </Form.Group>
                            : 
                            <></>
                        }

                        { contentType === "Song" ? 
                            <Form.Group className="mb-3">
                                <Form.Label className="manage-content">Lyrics Link</Form.Label>
                                <Form.Control type="text"  onChange={onLyricsChanged} />
                            </Form.Group>
                            : 
                            <></>
                        }

                        { contentType === "Album" ? 
                            <Form.Group className="mb-3">
                                <Form.Label className="manage-content">Song Ids</Form.Label>
                                <Form.Control type="text"  onChange={onSongsChanged} />
                            </Form.Group>
                            : 
                            <></>
                        }

                        <Form.Group className="mb-3">
                            <Form.Label className="manage-content">Description</Form.Label>
                            <Form.Control type="text"  onChange={onDescChanged} as="textarea" rows="3" cols="20" name="usrtxt" wrap="hard"/>
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className="manage-create-content" onClick={() => handleCreate(contentType)}>
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

  return (
    <>
      <Button variant="primary" onClick={handleShow} className="manage-content">
        New {contentType}
      </Button>

      <div>{content}</div>
    </>
  );
};  