import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, Form } from 'react-bootstrap';
import EditButton from '../../buttons/EditButton';
import { useEditSongMutation, selectSongById } from '../../../features/songsSlice';

export default function EditSongButton({ props }) {

    const song = useSelector(state => selectSongById(state, props.id));
    
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [
        updateSong, // This is the mutation trigger
        { isLoading: isUpdating }, // This is the destructured mutation result
    ] = useEditSongMutation();

    const [ songTitle, setTitle ] = useState(song.songTitle);
    const [ songLink, setLink ] = useState(song.songLink);
    const [ songLyrics, setLyrics ] = useState(song.songLyrics);
    const [ songArtwork, setArtwork ] = useState(song.songArtwork);
    const [ songDesc, setDesc ] = useState(song.songDesc);

    const onTitleChanged = (e) => setTitle(e.target.value);
    const onLinkChanged = (e) => setLink(e.target.value);
    const onLyricsChanged = (e) => setLyrics(e.target.value);
    const onArtworkChanged = (e) => setArtwork(e.target.value);
    const onDescChanged = (e) => setDesc(e.target.value);

    const handleUpdate = async (e) => {
        try {
            await updateSong({ id: song.id, songTitle, songDesc, songLink, songArtwork, songLyrics })
        }
        catch (error) {
            console.log('Failed to update song', song)
        }

        window.location.reload(false);
    }

    let content = renderModal();

    function renderModal() {
        return (
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                <Modal.Title>Edit {props.data}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label className="manage-content">Title</Form.Label>
                            <Form.Control type="text" value={songTitle} onChange={onTitleChanged} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="manage-content">Artwork Link</Form.Label>
                            <Form.Control type="text" value={songArtwork} onChange={onArtworkChanged} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="manage-content">Landar Link</Form.Label>
                            <Form.Control type="text" value={songLink} onChange={onLinkChanged} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="manage-content">Lyrics Link</Form.Label>
                            <Form.Control type="text" value={songLyrics} onChange={onLyricsChanged} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="manage-content">Description</Form.Label>
                            <Form.Control type="text" value={songDesc} onChange={onDescChanged} as="textarea" rows="3" cols="20" name="usrtxt" wrap="hard"/>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className="manage-create-content" onClick={() => handleUpdate()}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <>
            <EditButton onClick={handleShow} className="manage-content" />
            <div>{content}</div>
        </>
    );
};