import Navigation from './components/Navigation';
import { Routes, Route } from 'react-router-dom';
import Songs from './pages/Songs'; 
import SongDetail from './pages/SongDetail';
import Albums from './pages/Albums';
import AlbumDetail from './pages/AlbumDetail';
import Abouts from './pages/About';
import Footer from './components/Footer';
import Manage from './pages/Manage';
import Home from './pages/Home';
import Login from './pages/Login';
import ProtectedRoute from './components/ProtectedRoute';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import Prefetch from './features/auth/Prefetch';

const GlobalStyle = createGlobalStyle`
body {
  margin: 0;
  padding: 0;
  background: #212121
}`;

const theme = {
  primaryColor: '#FFFFFF',
  secondaryColor: '#CCCCCC',
  ternaryColor: '#ee1c27fb',
  binaryColor: '#616161', // navigation bar color
  fontFamily1: 'Archivo, sans-serif', //nav font
  fontFamily2: 'Lato, sans-serif', // headers
  fontFamily3: 'Roboto Condensed, sans-serif' //body content
}

function App() {
  return (
   <>
      <div className="App">
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Navigation />
          <Routes>
            <Route element={<Prefetch /> }>
              <Route path="songs" element={ <Songs /> } />
              <Route path="/songs/*" element={ <SongDetail /> } />
              <Route path="albums" element={ <Albums /> } />
              <Route path="/albums/*" element={ <AlbumDetail /> } />
              <Route path="about" element={ <Abouts /> } />
              <Route path="/" element={ <Home /> } />
              <Route path="/login" element={ <Login /> } />
              <Route path='/manage' element={<ProtectedRoute/>}>
                <Route path='/manage' element={ <Manage />} />
              </Route>
            </Route>
          </Routes>
          <Footer />
        </ThemeProvider>  
      </div>  
   </>
  );
};

export default App;
