import Button from 'react-bootstrap/Button';
import { AiFillEdit } from "react-icons/ai";

function EditButton( props ) {

  return (
    <>
        <Button variant="light" {...props}>
            <AiFillEdit />
        </Button>
    </>
  );
}

export default EditButton;