import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';
import Logo from '../assets/Logo.jpg';
import { NavbarWrapper, NavLinkWrapper } from '../styles/Navigation.js';

function Navigation() {

	var navActiveColor = '#FFFFFF';
	var navInactiveColor = '#212121';

	const isAuthenticated = localStorage.getItem("isAuthenticated");

	const [ expanded, setExpanded ] = useState(false);

  return (
	<NavbarWrapper>
		<Navbar expand="sm" expanded={expanded}>
			<Container fluid>
				<Navbar.Brand href="/">
					<img 
					src={Logo}
					width="65"
					height="65"
					className="d-inline-block align-top"
					alt="Slow Lane"
					style={{borderRadius: "3px"}}
				/>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")}/>
				<Navbar.Collapse id="basic-navbar-nav">
					<NavLinkWrapper>
						<Nav className="me-auto">
							<NavLink
								onClick={() => setExpanded(false)}
								className={"nav-link"}
								to="/about"
								style={({ isActive }) => {
									return {
									color: isActive ? navActiveColor : navInactiveColor,
									};
								}}
								>
								ABOUT
							</NavLink>
							<NavLink
								onClick={() => setExpanded(false)}
								className={"nav-link"}
								to="/albums"
								style={({ isActive }) => {
									return {
									color: isActive ? navActiveColor : navInactiveColor,
									};
								}}
								>
								ALBUMS
							</NavLink>
							<NavLink
								onClick={() => setExpanded(false)}
								className={"nav-link"}
								to="/songs"
								style={({ isActive }) => {
									return {
									color: isActive ? navActiveColor : navInactiveColor,
									};
								}}
								>
								SONGS
							</NavLink>

							{isAuthenticated ? 
								<>
									<NavLink
										onClick={() => setExpanded(false)}
										className={"nav-link"}
										to="/manage"
										style={({ isActive }) => {
											return {
											color: isActive ? navActiveColor : navInactiveColor,
											};
										}}
										>
										MANAGE
									</NavLink>
								</> 
								: 
								<></>
							}
							
						</Nav>
					</NavLinkWrapper>
				</Navbar.Collapse>
			</Container>
    	</Navbar>
	</NavbarWrapper>
  );
};

export default Navigation;