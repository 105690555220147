import React from 'react';
import { useSelector } from 'react-redux';
import { selectAlbumById } from '../../features/albumsSlice';
import { useNavigate } from 'react-router-dom';

const Album = ({ albumId }) => {
    
    const album = useSelector(state => selectAlbumById(state, albumId));
    const navigate = useNavigate();

    const handleAlbumClick = (album) => {
  
      const urlRedirect = album.albumTitle.replace(/\s+/g, '-').toLowerCase();
  
      navigate(`/albums/${urlRedirect}`, { state: { album } }); // redirect to description component and pass in data
    }

    return (
        <>
          <div className="artworkBox"> 
            <img 
              src={album.albumArtwork} 
              alt="Slow Lane Album Artwork"
              className="artwork"
              onClick={ () => handleAlbumClick(album) }
            />
          </div>
        </>
      );
};

export default Album;