import React from 'react';
import { useSelector } from 'react-redux';
import { selectSongById } from '../../features/songsSlice';
import { useNavigate } from 'react-router-dom';
import '../../styles/ContentImages.css';

const Song = ({ songId }) => {

  const song = useSelector(state => selectSongById(state, songId));
  const navigate = useNavigate();

  const handleSongClick = (song) => {

    const urlRedirect = song.songTitle.replace(/\s+/g, '-').toLowerCase();

    navigate(`/songs/${urlRedirect}`, { state: { song } }); // redirect to description component and pass in data
    }

  return (
    <>
      <div className="artworkBox"> 
        <img
            src={song.songArtwork} 
            alt="Slow Lane Song Artwork"
            className="artwork"
            onClick={ () => handleSongClick(song) }
        />
      </div>
    </>
  );
};

export default Song;