import React from 'react';

export default function TableHeader({ props }) {

    var headings = props;

    return (
        <thead>
            <tr>
                {Array.from(headings).map((header, index) => (
                    <th key={index}>{header}</th>
                ))}
            </tr>
      </thead>
    );
};