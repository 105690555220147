import React from 'react';
import { useGetAlbumsQuery } from '../features/albumsSlice';
import Album from '../components/albums/Album';
import { Row, Col } from 'react-bootstrap';
import { ContainerContent, PageConntent ,ContentTitle, NoFoundData } from '../styles/Content.js';
import Spinner from '../components/Spinner.js';

export default function Albums() {

    const {
        data: albums,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetAlbumsQuery();

    let content;

    if (isLoading) {
        content = <Spinner color={"#CCCCCC"} />
    }
    else if (!isSuccess) {
        content = <NoFoundData>COMING SOON</NoFoundData> // display empty div if no data is found
    }
    else if (isSuccess) {
        const { ids } = albums;

        content = 
                <Row xs={1} lg={3}>
                    {ids.map((albumId) => (
                    <Col key={albumId}>
                        <Album key={albumId} albumId={albumId}/>
                    </Col>
                    ))}
                </Row>
    }
    else if (isError) {
        content = <div>{error.toString()}</div>
    }

    return (
        <>
            <ContainerContent>
                <ContentTitle>ALBUMS</ContentTitle>
                <PageConntent>
                    <div>
                        {content}
                    </div>
                </PageConntent>
            </ContainerContent>
        </>
    );
};