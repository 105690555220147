import React from 'react';
import { useSelector } from 'react-redux';
import { selectSongById } from '../../features/songsSlice';
import '../../styles/ContentDetail.css';

export const Song = ({ songId }) => {

  const song = useSelector(state => selectSongById(state, songId));
  
  return (
    <>
      {song.songLink !== null || song.songLink !== undefined || !song.songLink !== "" || song.songTitle !== null || song.songTitle !== undefined  
        ||song.songTitle !== "" ? 
        <>
          <li className="album-songs">
            <a href={song.songLink} target='_blank' rel="noreferrer" className="album-songs-link">{song.songTitle.toUpperCase()}</a>
          </li>
        </> 
        : 
        <></>
      }
    </>
  );
};