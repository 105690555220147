import { store } from '../../app/store';
import { extendedAlbumsApiSlice } from '../albumsSlice';
import { extendedSongsApiSlice } from '../songsSlice';
import { extendedAboutsApiSlice } from '../aboutsSlice';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

const Prefetch = () => {
    useEffect(() => {
        const albums = store.dispatch(extendedAlbumsApiSlice.endpoints.getAlbums.initiate());
        const songs = store.dispatch(extendedSongsApiSlice.endpoints.getSongs.initiate());
        const abouts = store.dispatch(extendedAboutsApiSlice.endpoints.getAbouts.initiate());

        console.log(albums, songs, abouts);

        return () => {
            console.log('unsubscribing');
            console.log(albums, songs, abouts);
            albums.unsubscribe();
            songs.unsubscribe();
            abouts.unsubscribe();
        }
    }, [])

    return <Outlet />
};

export default Prefetch;