import Button from 'react-bootstrap/Button';
import { AiOutlineDelete } from "react-icons/ai";

function DeleteButton( props ) {

  return (
    <>
        <Button variant="light" {...props}>
            <AiOutlineDelete />
        </Button>
    </>
  );
}

export default DeleteButton;