import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSongById } from '../../../features/songsSlice';
import DeleteButton from '../../buttons/DeleteButton';
import DeleteModal from '../../modals/DeleteModal';
import EditSongButton from '../edit/EditSong';

export const Song = ({ songId }) => {

    const song = useSelector(state => selectSongById(state, songId));

    const props = {
        type: "song",
        id: song.id,
        data: song.songTitle
    };

    ////////////////////////////////////////////////////////////////////////////////////Modal

    const [ showDelete, setShowDelete ] = useState(false);

    const handleDeleteClose = () => setShowDelete(false);
    const handleDeleteModalShow = () => setShowDelete(true);

    return (
        <>
            <td><img src={song.songArtwork} alt="song artwork" style={{width: '50px'}}/></td>
            <td>
                {song.songTitle}
                <br />
                {song.id}
            </td>
            <td>{song.songDesc}</td>
            {song.songLink === "" || song.songLink === undefined || song.songLink === null ? 
                <>
                    <td></td>
                </> 
                : 
                <>
                    <td><a href={song.songLink} target="_blank" rel="noreferrer" className="manage-link">Link</a></td>
                </>
            }

            {song.songLyrics === "" || song.songLyrics === undefined || song.songLyrics === null ? 
                <>
                    <td></td>
                </> 
                : 
                <>
                    <td><a href={song.songLyrics} target="_blank" rel="noreferrer" className="manage-link">Link</a></td>
                </>
            }

            <td>
                <EditSongButton props={props} />   
                <DeleteButton  props={props} onClick={handleDeleteModalShow} />
            </td>

            <DeleteModal 
                show={showDelete} 
                onHide={handleDeleteClose}
                {...props}
            />
        </>
    );
};