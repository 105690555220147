import { Container, Row, Col } from 'react-bootstrap';
import { BsFacebook, BsSpotify, BsYoutube } from 'react-icons/bs';
import { NavLink } from "react-router-dom";
import '../styles/Footer.css';

function Footer() {

    const isAuthenticated = localStorage.getItem("isAuthenticated");

    const handleLogout = () => {
        localStorage.clear();
        window.location.pathname = "/";
    };

    return (
      <>
      <Container fluid className="footer-container">
        <Row className="footer-row">
            <Col sm>
                <div className="parent">
                    <div className="child-left">
                        <NavLink to="/" className={"footer-nav"}>
                            Slow Lane Rocks
                        </NavLink>
                    </div>
                    { isAuthenticated ? 
                        <div className="child-left">
                            <button className="footer-nav button" onClick={handleLogout}>Logout</button>
                        </div>
                        :
                        <div className="child-left login">
                            <NavLink to="/login" className={"footer-nav"}>
                            Login
                            </NavLink>
                        </div>
                    }
                </div>
            </Col>
            <Col className="footer-right">
                <div className="parent">
                    <div className="child-right">
                        <a href="https://www.facebook.com/profile.php?id=100087507450720" 
                            target="_blank" 
                            rel="noreferrer"
                            className="social-link footer">
                            <BsFacebook />
                        </a>
                    </div>
                    <div className="child-right">
                        <a 
                            href="https://open.spotify.com/artist/5nQ8dDvR8AQv2fmx8EuHay" 
                            target="_blank" 
                            rel="noreferrer"
                            className="social-link footer">
                            <BsSpotify />
                        </a>
                    </div>
                    <div className="child-right">
                        <a 
                            href="https://www.youtube.com/channel/UC2gooertJmx_t3GxFdr0XeQ" 
                            target="_blank" 
                            rel="noreferrer"
                            className="social-link footer">
                            <BsYoutube />
                        </a>
                    </div>
                </div>
            </Col>
        </Row>
      </Container>
      </>
    );
  };
  
  export default Footer;