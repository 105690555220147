// redux store is created using configureStore
// store.js is responsible for storing all the state of the application
// each application feature (songs, albums, etc.) will have own reducer function

import { configureStore } from '@reduxjs/toolkit'; 
import { apiSlice } from './api/apiSlice';
import authReducer from '../features/auth/authSlice';

export const store = configureStore({
    reducer: { 
        [apiSlice.reducerPath]: apiSlice.reducer,
        auth: authReducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(apiSlice.middleware),
    // devTools: true
    devTools: false
});