import React from 'react';
import { useSelector } from 'react-redux';
import { selectAboutById } from '../../features/aboutsSlice';
import { Row, Col, Card } from 'react-bootstrap';
import Logo from '../../assets/Logo.jpg';

const About = ({ aboutId }) => {

    const about = useSelector(state => selectAboutById(state, aboutId));

	return (
        <>
			<Row>
				<Col xl={12} className="about-col">
					<Card className="about-card">
						<Card.Img variant="top" src={about.aboutArtwork} className="about-artwork" />
						<Card.Body className="about-description">
							<Card.Text className="paragraph">
								{about.aboutDesc}
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col xl={12} className="about-col">
					<img src={Logo} alt="Slow Lane Rocks" className='logo' />
				</Col>
			</Row>
        </>
    );
};

export default About;