import React from 'react';
import { useGetSongsQuery } from '../features/songsSlice';
import { useSelector } from 'react-redux';
import { selectSongById } from '../features/songsSlice';
import { Row, Col } from 'react-bootstrap';
import { BsFillPlayBtnFill } from 'react-icons/bs';
import '../styles/JustReleased.css';
import { ContainerContent, PageConntent, ContentTitle } from '../styles/Content.js';
import Spinner from '../components/Spinner.js';

export default function Home() {

    const {
        data: songs,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetSongsQuery();

    let content;

    if (isLoading) {
        content = <Spinner color={"#CCCCCC"} />
    } 
    else if (!isSuccess) {
        content = <div></div> // display empty div if no data is found
    }
    else if (isSuccess) {
        const { ids } = songs;

        var copy = [...ids];

        content = <JustReleasedExcerpt songId={copy[0]} />
    } 
    else if (isError) {
        content = <div>{error.toString()}</div>
    }

    return (
        <ContainerContent>
            <div>
                {content}
            </div>
    </ContainerContent>
    );
};

let JustReleasedExcerpt = ({ songId }) => {

    const song = useSelector(state => selectSongById(state, songId));
    var date = new Date(song.createdAt);
    var justReleasedDate = date.toLocaleString('default', { month: 'short' }) + " " + date.getFullYear().toString().substr(2,2);
  
    return (
      <>
          <Row>
              <Col>
                  <ContentTitle className="just-released-title">JUST RELEASED {justReleasedDate.toUpperCase()}</ContentTitle>
              </Col>
          </Row>
          <PageConntent>
            <Row className="just-released-row">
                <Col className="just-released-col" lg={12}>
                    <img 
                    src={song.songArtwork} 
                    alt="Just Released Artwork"
                    className="just-released-artwork"
                    />
                    <div>
                        {song.songLink && (song.songLink !== null || song.songLink !== "" || song.songLink !== undefined) ? 
                        <>
                            <a href={song.songLink} target='blank' className="social-link just-released">                    
                                <BsFillPlayBtnFill />
                                <span>
                                    {song.songTitle.toUpperCase()}
                                </span>
                            </a>    
                        </> 
                        : 
                        <>
                            <a href="/" className="social-link just-released">   
                                <BsFillPlayBtnFill />
                                <span>
                                    {song.songTitle.toUpperCase()}
                                </span>
                            </a>
                        </>
                        }
                    </div>
                </Col>
            </Row>
          </PageConntent>
      </>
    );
};


