import React from 'react';
import { useGetAboutsQuery } from '../features/aboutsSlice';
import About from '../components/abouts/About.js';
import { Container } from 'react-bootstrap';
import '../styles/About.css';
import Spinner from '../components/Spinner.js';

export default function Abouts() {

    const {
        data: abouts,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetAboutsQuery();

    let content;

    if (isLoading) {
        content = <Spinner color={"#CCCCCC"} />
    }
    else if (!isSuccess) {
        content = <div></div> // display empty div if no data is found
    }
    if (isSuccess) {
        const { ids } = abouts;
        
        content = ids.map(aboutId => {
            return <About key={aboutId} aboutId={aboutId} />
        });
    }
    else if (isError) {
        if (error.status === 404) {
            content = <div>Sorry, no data to display.</div>
        }
        else {
            content = <div></div>
        }
    }
    return (
        <>
            <Container fluid className="hero">
                <div>
                    {content}
                </div>
            </Container>
        </>
    );
};