import * as React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { ContainerContent } from '../styles/Content.js';
import '../styles/ContentDetail.css';
import { BsFillPlayBtnFill } from 'react-icons/bs';

export default function SongDetail() {

    const location = useLocation();

    const song = location.state.song;

    var date = new Date(song.createdAt);
    var releasedDate = date.toLocaleString('default', { month: 'short' }) + " " + date.getFullYear();

    return (
        <>
            <ContainerContent>
                <Row className="content-detail-header">
                    <Col className="content-detail-title"> 
                        {song.songLink && (song.songLink !== null || song.songLink !== "" || song.songLink !== undefined) ? 
                        <>
                            <a href={song.songLink} target='blank' className="content-landar-main">                    
                                <BsFillPlayBtnFill />
                                <span id="content-title">
                                    {song.songTitle.toUpperCase()}
                                </span>
                            </a>    
                        </> 
                        : 
                        <>
                            <BsFillPlayBtnFill />
                            <span id="content-title">
                                {song.songTitle.toUpperCase()}
                            </span>
                        </>
                        }
                    </Col>
                </Row>
                <Row className="content-detail-header">
                    <Col className="content-detail-date">{releasedDate}</Col>
                </Row>
                <Row>
                    <Col lg={5} id="song-card-detail">
                        <Card className="content-card-detail">
                            <Card.Img variant="top" src={song.songArtwork} className="content-card-detail"/>
                            <Card.Body className="content-card-detail">
                                <Card.Text className="paragraph">
                                    {song.songDesc}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={7}>
                        <object data={song.songLyrics} type='application/pdf' width='100%' height='100%' >
                        </object>
                    </Col>
                </Row>
            </ContainerContent>
        </>
    );
};