import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import AlbumsTab from '../components/manage/albums/AlbumsTab';
import SongsTab from '../components/manage/songs/SongsTab';
import AboutTab from '../components/manage/about/AboutTab';
import { ContainerContent, PageConntent ,ContentTitle } from '../styles/Content.js';
import '../styles/Manage.css';



export default function Manage() {
    
    return (
        <>
            <ContainerContent>
                <ContentTitle>MANAGE</ContentTitle>
                <PageConntent>
                    <Tabs
                        defaultActiveKey=""
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="album" title="Albums">
                            <AlbumsTab />
                        </Tab>
                        <Tab eventKey="song" title="Songs">
                            <SongsTab />
                        </Tab>
                        <Tab eventKey="about" title="About">
                            <AboutTab />
                        </Tab>
                    </Tabs>
                </PageConntent>
            </ContainerContent>
        </>
    );
};
