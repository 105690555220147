import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, Form } from 'react-bootstrap';
import EditButton from '../../buttons/EditButton';
import { useEditAboutMutation, selectAboutById } from '../../../features/aboutsSlice';

export default function EditAboutButton({ props }) {

    const about = useSelector(state => selectAboutById(state, props.id));
    
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [
        updateAbout, // This is the mutation trigger
        { isLoading: isUpdating }, // This is the destructured mutation result
    ] = useEditAboutMutation();

    const [ aboutArtwork, setArtwork ] = useState(about.aboutArtwork);
    const [ aboutDesc, setDesc ] = useState(about.aboutDesc);

    const onArtworkChanged = (e) => setArtwork(e.target.value);
    const onDescChanged = (e) => setDesc(e.target.value);

    const handleUpdate = async (e) => {
        try {
            await updateAbout({ id: about.id, aboutDesc, aboutArtwork })
        }
        catch (error) {
            console.log('Failed to update about', about)
        }

        window.location.reload(false);
    }

    let content = renderModal();

    function renderModal() {
        return (
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                <Modal.Title>Edit About</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label className="manage-content">Artwork Link</Form.Label>
                            <Form.Control type="text" value={aboutArtwork} onChange={onArtworkChanged} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="manage-content">Description</Form.Label>
                            <Form.Control type="text" value={aboutDesc} onChange={onDescChanged} as="textarea" rows="3" cols="20" name="usrtxt" wrap="hard"/>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className="manage-create-content" onClick={() => handleUpdate()}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <>
            <EditButton onClick={handleShow} className="manage-content" />
            <div>{content}</div>
        </>
    );
};