import React from 'react';
import { useGetAlbumsQuery } from '../../../features/albumsSlice';
import { Table } from 'react-bootstrap';
import TableHeader from '../TableHeader';
import { Album } from './Album';
import NewContentButton from '../NewContentButton';

const AlbumsTab = () => {

    const {
        data: albums,
        isLoading,
        isSuccess,
        IsError,
        error 
    } = useGetAlbumsQuery();

    let content;

    if (isLoading) {
        content = <div>Loadings album data...</div>
    }
    else if (!isSuccess) {
        content = <div>No album data to display</div>
    }
    else if (isSuccess) {
        const { ids } = albums;

        content = renderAlbumsContent({ ids });
    }
    else if (IsError) {
        content = <div>{error.toString()}</div>
    }

    function renderAlbumsContent({ ids }) {
        return (
            <div>
                <Table striped bordered hover>
                    <TableHeader props={ ['Artwork', 'Title', 'Description', 'Landr', 'Manage'] }/>
                    <tbody>
                        {Array.from(ids).map((albumId, index) => (
                        <tr key={index}>
                            <Album key={index} albumId={albumId} />
                        </tr>
                        ))}
                    </tbody>
                </Table>
            </div> 
        );
    };

    return (
        <>
            <div>
                <NewContentButton contentType={"Album"} />
            </div>
            <br />
            <div>
                {content}
            </div>
        </>
    );
};

export default AlbumsTab;