import { createSlice } from '@reduxjs/toolkit'; 

const authSlice = createSlice({
    name: 'auth',
    initialState: { token: null }, // will be expecting to recieve the token back from our api
    reducers: {
        setCredentials: (state, action) => {
            const { accessToken } = action.payload
            state.token = accessToken
        },
        logOut: (state, action) => {
            state.token = null
        }
    }
});

export const { setCredentials, logOut } = authSlice.actions;
export default authSlice.reducer;
export const selectCurrentToken = (state) => state.auth.token;