import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import EditButton from '../../buttons/EditButton';
import { useEditAlbumMutation, selectAlbumById } from '../../../features/albumsSlice';
import { selectSongById } from '../../../features/songsSlice';

export default function EditAlbumButton({ props }) {

    const album = useSelector(state => selectAlbumById(state, props.id));
    
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [
        updateAlbum, // This is the mutation trigger
        { isLoading: isUpdating }, // This is the destructured mutation result
    ] = useEditAlbumMutation();

    const [ albumTitle, setTitle ] = useState(album.albumTitle);
    const [ albumLink, setLink ] = useState(album.albumLink);
    const [ albumArtwork, setArtwork ] = useState(album.albumArtwork);
    const [ albumSongIds, setAlbumSongIds ] = useState(album.albumSongs)
    const [ albumDesc, setDesc ] = useState(album.albumDesc);

    const onTitleChanged = (e) => setTitle(e.target.value);
    const onLinkChanged = (e) => setLink(e.target.value);
    const onArtworkChanged = (e) => setArtwork(e.target.value);
    const onAlbumSongIdsChanged = (e) => setAlbumSongIds(e.target.value);
    const onDescChanged = (e) => setDesc(e.target.value);

    const albumSongIdsList = [...album.albumSongs];

    const handleUpdate = async (e) => {
        try {

            const albumSongsString = JSON.stringify(albumSongIds);

            console.log("Album Song String", albumSongsString, typeof(albumSongsString));

            const removeBrackets = albumSongsString.replace(/[\[\]']+/g,'').replace(/['"]+/g, '');
            console.log("remove brackets", removeBrackets);

            if (removeBrackets.includes(",")) {
                const trimmedSongs = removeBrackets.split(" ").join("");

                const albumSongs = trimmedSongs.split(',');
                console.log("albumSongs", albumSongs);

                await updateAlbum({ id: album.id, albumTitle, albumDesc, albumLink, albumArtwork, albumSongs });
            }
            else {
                const albumSongs = removeBrackets.split(',');
                console.log("albumSongs", albumSongs);

                await updateAlbum({ id: album.id, albumTitle, albumDesc, albumLink, albumArtwork, albumSongs });
            }
        }
        catch (error) {
            console.log('Failed to update album', album);
        }

       window.location.reload(false);
    }

    let content = renderModal();

    function renderModal() {
        return (
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                <Modal.Title>Edit {props.data}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label className="manage-content">Title</Form.Label>
                            <Form.Control type="text" value={albumTitle} onChange={onTitleChanged} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="manage-content">Landar Link</Form.Label>
                            <Form.Control type="text" value={albumLink} onChange={onLinkChanged} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="manage-content">Artwork Link</Form.Label>
                            <Form.Control type="text" value={albumArtwork} onChange={onArtworkChanged} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="manage-content">Song Ids</Form.Label>
                            <Form.Control type="text" value={albumSongIds} onChange={onAlbumSongIdsChanged} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="manage-content">Description</Form.Label>
                            <Form.Control type="text" value={albumDesc} onChange={onDescChanged} as="textarea" rows="3" cols="20" name="usrtxt" wrap="hard"/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Row>
                                <Col>
                                {Array.from(albumSongIdsList).map((songId, index) => (
                                    <AlbumSongs key={index} songId={songId} />
                                ))}
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className="manage-create-content" onClick={() => handleUpdate()}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <>
            <EditButton onClick={handleShow} className="manage-content" />
            <div>{content}</div>
        </>
    );
};

const AlbumSongs = ({ songId }) => {
    const song = useSelector(state => selectSongById(state, songId));
    return (
        <div>
            <label>{song.songTitle}</label>
        </div>
    )
};