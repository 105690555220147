import styled from "styled-components";
import { breakpoints } from './Breakpoints';

//main style for about, albums, and songs pages
//////////////////////////////////////////////////////All Pages
export const ContainerContent = styled.div`
    padding: 5rem;

    @media (max-width: ${breakpoints.md}) {
        padding: 2.5rem;
    }
`;

export const PageConntent = styled.div`
    padding: 4rem 0;

    @media (max-width: ${breakpoints.md}) {
        padding: 2.5rem 0;
    }
`;

export const NoFoundData = styled.div`
    color: ${p => p.theme.secondaryColor};
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.2rem;
`;

export const ContentTitle = styled.h1`
    color: ${p => p.theme.primaryColor};
    font-family: ${p => p.theme.fontFamily2};
    font-size: 2.6rem;
    font-weight: 700;

    @media (max-width: ${breakpoints.md}) {
        font-size: 2rem;
    }

    @media (max-width: ${breakpoints.sm}) {
        font-size: 1.5rem;
    }

    @media (max-width: ${breakpoints.xs}) {
        font-size: 1.2rem;
    }
`;