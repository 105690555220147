import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useGetSongsQuery } from '../features/songsSlice.js'
import { Song } from '../components/albums/Song.js';
import { ContainerContent } from '../styles/Content.js';
import '../styles/ContentDetail.css';
import { BsFillPlayBtnFill } from 'react-icons/bs';

export default function AlbumDetail() {

    const location = useLocation();

    const album = location.state.album;

    var noSongContent = false;
    if (album.albumSongs === undefined) {
        noSongContent = true;
    }
    var date = new Date(album.createdAt);
    var releasedDate = date.toLocaleString('default', { month: 'short' }) + " " + date.getFullYear();

    var albumSongs = [];
    if (!noSongContent) {
       albumSongs = [...album.albumSongs];
    }

    var albumHasContent2 = false

    let albumSlice1 = [];
    let albumSlice2 = [];

    if (albumSongs.length > 10) {
        albumHasContent2 = true;
        albumSlice1 = albumSongs.slice(0, 10);
        albumSlice2 = albumSongs.slice(albumSlice1.length, albumSongs.length);
    }

    const [isMobile, setIsMobile] = useState(false);

    const {
        data: songs,
        isLoading,
        isSuccess,
        IsError,
        error 
    } = useGetSongsQuery();

    let content;
    let content2;

    if (isLoading) {
        content = <div></div>
    }
    else if (!isSuccess) {
        content = <div></div>
    }
    else if (isSuccess) {

        if (albumSlice2.length !== 0 && !isMobile) {
            content = albumSlice1.map(songId => <Song key={songId} songId={songId} />)
            content2 = albumSlice2.map(songId => <Song key={songId} songId={songId} />)
        }
        else if (albumSlice2.length !== 0 && isMobile) {
            content = albumSongs.map(songId => <Song key={songId} songId={songId} />)
        }
        else {
            content = albumSongs.map(songId => <Song key={songId} songId={songId} />)
        }
    }
    else if (IsError) {
        content = <div>{error.toString()}</div>
    }

    if (albumHasContent2) {
        var colLeft = 5;
        var colRight = 7;
    }
    else {
        colLeft = 6;
        colRight = 6;
    }

    //choose the screen size 
    const handleResize = () => {
        if (window.innerWidth < 991) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }
    // create an event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })

    return (
        <ContainerContent>
            <Row className="content-detail-header">
                <Col className="content-detail-title"> 
                    {album.albumLink && (album.albumLink !== null || album.albumLink !== "" || album.albumLink !== undefined) ? 
                    <>
                        <a href={album.albumLink} target='blank' className="content-landar-main">                    
                            <BsFillPlayBtnFill />
                            <span id="content-title">
                                {album.albumTitle.toUpperCase()}
                            </span>
                        </a>    
                    </> 
                    : 
                    <>
                        <BsFillPlayBtnFill />
                        <span id="content-title">
                            {album.albumTitle.toUpperCase()}
                        </span>
                    </>
                    }
                  
                </Col>
            </Row>
            <Row className="content-detail-header">
                <Col className="content-detail-date">{releasedDate}</Col>
            </Row>
            <Row style={{color: "white"}}>
                <Col lg={colLeft} id="album-content">
                    <Card className="content-card-detail">
                        <Card.Img variant="top" src={album.albumArtwork} className="content-card-detail"/>
                        <Card.Body className="content-card-detail">
                            <Card.Text className="paragraph">
                                {album.albumDesc}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                { noSongContent ? 
                    <></> 
                    : 
                    <>
                        {!isMobile ? 
                        <>
                            <Col lg={colRight} id="album-content">
                                {albumHasContent2 ? 
                                    <Row>
                                        <Col>
                                            <ol>
                                                {content}
                                            </ol>
                                        </Col>
                                        <Col>
                                            <ol start="11">
                                                {content2}
                                            </ol>
                                        </Col>
                                    </Row>
                                    : 
                                    <Row>
                                        <Col>
                                            <ol>
                                                {content}
                                            </ol>
                                        </Col>
                                    </Row>
                                    }
                            </Col>
                        </> 
                        : 
                        <>
                             <Col lg={colRight} id="album-content">
                                {albumHasContent2 ? 
                                    <Row>
                                        <Col>
                                            <ol>
                                                {content}
                                            </ol>
                                        </Col>
                                    </Row>
                                    : 
                                    <Row>
                                        <Col>
                                            <ol>
                                                {content}
                                            </ol>
                                        </Col>
                                    </Row>
                                    }
                            </Col>
                        </>
                        }

                    </>
                }          
            </Row>
        </ContainerContent>
    );
};