import React from 'react';
import { useGetSongsQuery } from '../features/songsSlice';
import Song from '../components/songs/Song';
import { Row, Col } from 'react-bootstrap';
import { ContainerContent, PageConntent ,ContentTitle } from '../styles/Content.js';
import Spinner from '../components/Spinner.js';

export default function Songs() {

    const {
        data: songs,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetSongsQuery();

    let content;

    if (isLoading) {
        content = <Spinner color={"#CCCCCC"} />
    } 
    else if (!isSuccess) {
        content = <div></div> // display empty div if no data is found
    }
    else if (isSuccess) {
        const { ids } = songs;

        content = 
                <Row xs={1} lg={4}>
                    {ids.map((songId) => (
                    <Col key={songId}>
                        <Song key={songId} songId={songId} />
                    </Col>
                    ))}
                </Row>      
    } 
    else if (isError) {
        content = <div>{error.toString()}</div>
    }

    return (
        <>
            <ContainerContent>
                <ContentTitle>SONGS</ContentTitle>
                <PageConntent>
                    <div>
                        {content}
                    </div>
                </PageConntent>
            </ContainerContent>
        </>
    );
};