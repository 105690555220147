import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAlbumById } from '../../../features/albumsSlice';
import DeleteButton from '../../buttons/DeleteButton';
import DeleteModal from '../../modals/DeleteModal';
import EditAlbumButton from '../edit/EditAlbum';

export const Album = ({ albumId }) => {

    const album = useSelector(state => selectAlbumById(state, albumId));

    const props = {
        type: "album",
        id: album.id,
        data: album.albumTitle,
    };

    ////////////////////////////////////////////////////////////////////////////////////Modal

    const [ showDelete, setShowDelete ] = useState(false);

    const handleDeleteClose = () => setShowDelete(false);
    const handleDeleteModalShow = () => setShowDelete(true);

    return (
        <>
            <td><img src={album.albumArtwork} alt="album artwork" style={{width: '50px'}}/></td>
            <td>{album.albumTitle}</td>
            <td>{album.albumDesc}</td>
            {album.albumLink === "" || album.albumLink === undefined || album.albumLink === null ? 
                <>
                    <td></td>
                </> 
                : 
                <>
                    <td><a href={album.albumLink} target="_blank" rel="noreferrer" className="manage-link">Link</a></td>
                </>
            }
            <td>
                <EditAlbumButton props={props} />
                <DeleteButton  props={props} onClick={handleDeleteModalShow} />
            </td>

            <DeleteModal 
                show={showDelete} 
                onHide={handleDeleteClose}
                {...props}
            />
        </>
    );
};