import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAboutById } from '../../../features/aboutsSlice';
import DeleteButton from '../../buttons/DeleteButton';
import EditButton from '../../buttons/EditButton';
import DeleteModal from '../../modals/DeleteModal';
import EditAboutButton from '../edit/EditAbout';

export const About = ({ aboutId }) => {

    const about = useSelector(state => selectAboutById(state, aboutId));

    const props = {
        type: "about",
        id: about.id,
    };

    ////////////////////////////////////////////////////////////////////////////////////Modal

    const [ showDelete, setShowDelete ] = useState(false);

    const handleDeleteClose = () => setShowDelete(false);
    const handleDeleteModalShow = () => setShowDelete(true);

    return (
        <>
            <td><img src={about.aboutArtwork} alt="about artwork" style={{width: '50px'}}/></td>
            <td>{about.aboutDesc}</td>
            <td>
                <EditAboutButton props={props} />
                <DeleteButton  props={props} onClick={handleDeleteModalShow} />
            </td>

            <DeleteModal 
                show={showDelete} 
                onHide={handleDeleteClose}
                {...props}
            />
        </>
    );
};