import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCredentials } from '../features/auth/authSlice';
import { useLoginMutation } from '../features/auth/authApiSlice';
import { Container, Form } from 'react-bootstrap';
import { ContainerContent, PageConntent ,ContentTitle } from '../styles/Content.js';
import '../styles/Login.css';

function Login() {

    const userRef = useRef();
    const errRef = useRef();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const handleUserInput = (e) => setUsername(e.target.value);
    const handlePasswordInput = (e) => setPassword(e.target.value);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [login, { isLoading }] = useLoginMutation();

    useEffect(() => {
        userRef.current.focus()
    }, []);

    useEffect(() => {
        setErrorMessage('');
    }, [ username, password ]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const { accessToken } = await login({ username, password }).unwrap();
            dispatch(setCredentials({ accessToken }));
            localStorage.setItem("isAuthenticated", "true");
            setUsername('');
            setPassword('');
            navigate('/manage');
            window.location.reload();
        } 
        catch (err) {
            if (!err.status) {
                setErrorMessage('No server response');
            } else if (err.status === 400) {
                setErrorMessage('Missing required username or password');
            } else if (err.status === 401) {
                setErrorMessage('Unauthorized access');
            } else {
                setErrorMessage(err.data?.message);
            }
            errRef.current.focus();
        }
    };

    const errClass = errorMessage ? "errmsg" : "offscreen";

    if (isLoading) return <p>Loading...</p>

        const content = (
        <ContainerContent>
            <ContentTitle>LOGIN</ContentTitle>
            <PageConntent>
                <main>
                    <p ref={errRef} className={errClass} aria-live="assertive">{errorMessage}</p>

                    <Form className="form" onSubmit={handleSubmit}>
                        <Form.Label htmlFor="username">Username</Form.Label>
                        <Form.Control
                            type="text"
                            id="username"
                            ref={userRef}
                            value={username}
                            onChange={handleUserInput}
                            autoComplete="off"
                            required
                        />

                        <Form.Label htmlFor="password">Password</Form.Label>
                        <Form.Control
                            type="password"
                            id="password"
                            onChange={handlePasswordInput}
                            value={password}
                            required
                        />
                        <button className="login">Login</button>
                    </Form>
                </main>
            </PageConntent>
        </ContainerContent>
    );

    return content;
};

export default Login;