import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectSongById, useDeleteSongMutation } from '../../features/songsSlice';
import { selectAboutById, useDeleteAboutMutation } from '../../features/aboutsSlice';
import { selectAlbumById, useDeleteAlbumMutation } from '../../features/albumsSlice';

function DeleteModal( props ) {

    ////////////////////////////////////////////////////////////////////////////////////Song
    const isSongDelete = props.type === "song" ? true : false;

    const song = useSelector(state => selectSongById(state, props.id));

    const [deleteSong, {
        isSuccess: isDelSuccessSong,
        isError: isDelErrorSong,
        error: delerrorSong
    }] = useDeleteSongMutation();


    ////////////////////////////////////////////////////////////////////////////////////About
    const isAboutDelete = props.type === "about" ? true : false;

    const about = useSelector(state => selectAboutById(state, props.id));

    const [deleteAbout, {
        isSuccess: isDelSuccessAbout,
        isError: isDelErrorAbout,
        error: delerrorAbout
    }] = useDeleteAboutMutation();

    ////////////////////////////////////////////////////////////////////////////////////Album
    const isAlbumDelete = props.type === "album" ? true : false;

    const album = useSelector(state => selectAlbumById(state, props.id));

    const [deleteAlbum, {
        isSuccess: isDelSuccessAlbum,
        isError: isDelErrorAlbum,
        error: delerrorAlbum
    }] = useDeleteAlbumMutation();

    const onDeleteModalClicked = async () => {

        if (isAlbumDelete) {
            try {
                await deleteAlbum({ id: album.id });
            }
            catch (error) {
                console.log('Failed to delete album', album)
            }
        }
        else if (isSongDelete) {
            try {
                await deleteSong({ id: song.id });
            }
            catch (error) {
                console.log('Failed to delete song', song)
            }
        }
        else if (isAboutDelete) {
            try {
                await deleteAbout({ id: about.id })
            }
            catch (error) {
                console.log('Failed to delete about', about)
            }
        }

        window.location.reload(false);
    }

  return (
    <>
        <Modal {...props} >
            <Modal.Header closeButton>
                <Modal.Title>Warning...</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isAlbumDelete ? 
                    <>Are you sure you want to delete the {props.type} {props.data}?</> 
                    : 
                    <></>
                }
                {isSongDelete ? 
                    <>Are you sure you want to delete the {props.type} {props.data}?</> 
                    : 
                    <></>
                }
                {isAboutDelete ? 
                    <>Are you sure you want to delete the {props.type}?</> 
                    : 
                    <></>
                }
            </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={onDeleteModalClicked}>
                        Delete
                    </Button>
                </Modal.Footer>
        </Modal>
    </>
  );
}

export default DeleteModal;