import styled from "styled-components";
import { breakpoints } from './Breakpoints';

export const NavbarWrapper = styled.div`
    background-color: ${p => p.theme.binaryColor};
    padding-left: 1rem;
`;

export const NavLinkWrapper = styled.div`
    font-family: ${p => p.theme.fontFamily1};
    font-weight: 900;
    font-size: large;
    padding: 0 0.8rem;
    flex-direction: row;

    @media (max-width: ${breakpoints.sm}) {
        padding: 0;
        padding-top: 1rem;
    }
`;