import React from 'react';
import { useGetSongsQuery } from '../../../features/songsSlice';
import { Table } from 'react-bootstrap';
import TableHeader from '../TableHeader';
import { Song } from './Song';
import NewContentButton from '../NewContentButton';

const SongsTab = () => {

    const {
        data: songs,
        isLoading,
        isSuccess,
        IsError,
        error 
    } = useGetSongsQuery();

    let content;

    if (isLoading) {
        content = <div>Loadings song data...</div>
    }
    else if (!isSuccess) {
        content = <div>No song data to display</div>
    }
    else if (isSuccess) {
        const { ids } = songs;

        content = renderSongsContent({ ids });
    }
    else if (IsError) {
        content = <div>{error.toString()}</div>
    }

    function renderSongsContent({ ids }) {
        return (
            <div>
                <Table striped bordered hover>
                    <TableHeader props={ ['Artwork', 'Title', 'Description', 'Landr', 'Lyrics',  'Manage'] }/>
                    <tbody>
                        {Array.from(ids).map((songId, index) => (
                        <tr key={index}>
                            <Song key={index} songId={songId} />
                        </tr>
                        ))}
                    </tbody>
                </Table>
            </div> 
        );
    };

    return (
        <>
            <div>
                <NewContentButton contentType={"Song"} />
            </div>
            <br />
            <div>
                {content}
            </div>
        </>
    );
};

export default SongsTab;