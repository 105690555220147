import React from 'react';
import { useGetAboutsQuery } from '../../../features/aboutsSlice';
import { Table } from 'react-bootstrap';
import TableHeader from '../TableHeader';
import { About } from './About';
import NewContentButton from '../NewContentButton';

const AboutTab = () => {

    const {
        data: abouts,
        isLoading,
        isSuccess,
        IsError,
        error 
    } = useGetAboutsQuery();

    let content;
    var hasCurrentAbout = false;

    if (isLoading) {
        content = <div>Loadings about data...</div>
    }
    else if (!isSuccess) {
        content = <div>No about data to display</div>
    }
    else if (isSuccess) {
        const { ids } = abouts;

        content = renderAboutsContent({ ids });

        hasCurrentAbout = true;
    }
    else if (IsError) {
        content = <div>{error.toString()}</div>
    }

    function renderAboutsContent({ ids }) {
        return (
            <div>
                <Table striped bordered hover>
                    <TableHeader props={ ['Artwork', 'Description', 'Manage'] }/>
                    <tbody>
                        {Array.from(ids).map((aboutId, index) => (
                        <tr key={index}>
                            <About key={index} aboutId={aboutId} />
                        </tr>
                        ))}
                    </tbody>
                </Table>
            </div> 
        );
    };

    return (
        <>
        { hasCurrentAbout ? 
            <></> 
            : 
            <div>
                <NewContentButton contentType={"About"} />
            </div>
        }

            <br />
            <div>
                {content}
            </div>
        </>
    );
};

export default AboutTab;